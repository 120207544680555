@import "config";


.blue-form {
  .ant-form-item-label label {
    color: $blue;
    font-size: 18px;
    font-weight: 500;
  }

  .ant-tooltip-inner {
    background-color: $blue;
  }

  .ant-tooltip-arrow {
    --antd-arrow-background-color: #006FBA;
  }
}

.ant-picker-panel-container {
  background: $white !important;
}

// Collapse

.hide-collapse-header .ant-collapse-header {
  display: none !important;
}

.hide-collapse-header .ant-collapse-content-box {
  padding: 0 !important;
}

//Alert
.ant-alert-icon {
  font-size: 16px !important;
}

.ant-alert {
  display: flex;
  align-items: center;
}

.ant-alert-description {
  font-size: 12px;
}

// Custom Table
.separate-rows-table {
  .ant-table {
    background: transparent;
  }

  .ant-table-body > table {
    border-collapse: separate;
    border-spacing: 0 19px;
    margin-top: -12px;
  }

  .ant-table-cell {
    background-color: $white;
  }
}

.blue-header-table {
  .ant-table-thead > tr th {
    background-color: $blue !important;
    color: $white !important;

    .ant-table-column-sorter {
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }
}

.custom-rows-expanded {
  .ant-table-expanded-row {
    transform: translateY(-19px);
    > td {
      background-color: rgba(218, 218, 218, 0.40) !important;
    }
  }
}